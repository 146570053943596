<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="data !== null">
    <InputRadio
        v-model="data"
        :options="booleanYesNo"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { willRelationshipHelpers } from "../../../../fieldsets/will/helpers/willRelationshipHelpers";
import { willPeopleHelpers } from "../../../../fieldsets/will/helpers/willPeopleHelpers";
import { questionLoadHelpers } from "../../../helpers/questionLoadHelpers";
import { general } from "../../../options/general";

import QuestionBase from "../../../QuestionBase";
import InputRadio from "../../../inputs/InputRadio";
import {textHelpers} from "@/mixins/textHelpers";
import {personHelpers} from "@/mixins/personHelpers";

export default {
  name: 'SecondaryYN',
  mixins: [general, questionLoadHelpers, willPeopleHelpers, willRelationshipHelpers, textHelpers, personHelpers],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: [Boolean],
      required: false
    },
    details: {
      type: Object,
      required: false
    },
    callbackFunction: {
      type: Function,
      required: true
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    },
    question() {
      return {
        title: `If ${this.listPeopleAddressDob({
          people: this.details.primary,
          noDob: true
        })} ${this.isAre({list: this.details.primary})} was unable or unwilling to look after your pets, would you like to appoint replacements?`,
        subTitle: `You should plan for every eventuality and name people who can step in if ${this.listPeopleAddressDob({
          people: this.details.primary,
          noDob: true
        })} ${this.hasHave({list: this.details.primary})} passed away before you.`,
        tip: null,
      }
    },
  },
  data () {
    return {
      db: {
        saveLocation: 'product_will_data',
        saveField: 'secondaryYNPets',
        formPath: 'data.pets.details.secondaryYN',
        jsonSaveField: 'secondaryYN',
        callbackFunction: this.callbackFunction
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
