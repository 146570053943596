<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Pets</h3>

    <SeparatePets
        v-model="form.data.pets.details.separatePets"
        :details="form.data.pets.details"
        :callbackFunction="enable"
    />

    <template v-if="details.separatePets">

      <PartnerPrimaryYN
          v-if="partner"
          v-model="form.data.pets.details.primaryPartner"
          :callbackFunction="syncOptions"
      />

      <div
          v-if="primaryRequired"
          class="question-box with-arrow">

        <PrimaryPets
            v-model="form.data.pets.details.primary"
            :details="form.data.pets.details"
            :optionsData="primaryOptions"/>

      </div>

      <SecondaryYN
          v-if="details.primary.length || details.secondary.length"
          v-model="form.data.pets.details.secondaryYN"
          :details="form.data.pets.details"
          :callbackFunction="clearSecondary"
      />

      <template v-if="secondaryRequired">

        <div v-show="details.primary.length || details.secondary.length"
             class="question-box with-arrow">

          <SecondaryPets
              v-model="form.data.pets.details.secondary"
              :details="form.data.pets.details"
              :optionsData="secondaryOptions"/>

        </div>
      </template>

      <AmountYN v-model="form.data.pets.details.amountYN"/>

      <div v-if="details.amountYN" class="question-box with-arrow">
        <AmountPets v-model="form.data.pets.details.cash.amount"/>
      </div>

    </template>


  </div>
</template>

<script>
import {willPeopleHelpers} from "./helpers/willPeopleHelpers";
import {fieldsetHelpers} from "../fieldsetHelpers";
import {willPetsHelpers} from "./helpers/willPetsHelpers";

import {clone} from "@/mixins/clone";
import {saveHelpers} from "@/views/questionnaires/saveHelpers";

import PartnerPrimaryYN from "../../question/questions/will/pets/PartnerPrimaryYN";
import PrimaryPets from "../../question/questions/will/pets/PrimaryPets";
import SecondaryPets from "../../question/questions/will/pets/SecondaryPets";
import SecondaryYN from "../../question/questions/will/pets/SecondaryYN";
import SeparatePets from "../../question/questions/will/pets/SeparatePets";
import AmountPets from "../../question/questions/will/pets/AmountPets";
import AmountYN from "../../question/questions/will/pets/AmountYN";

export default {
  name: 'Pets',
  mixins: [
    fieldsetHelpers,
    willPeopleHelpers,
    clone,
    saveHelpers,
    willPetsHelpers
  ],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    AmountYN,
    AmountPets,
    SeparatePets,
    SecondaryYN,
    SecondaryPets,
    PrimaryPets,
    PartnerPrimaryYN
  },
  data() {
    return {
      label: 'Pets', // used for steps progress
      primaryOptions: [],
      secondaryOptions: [],
      backupOptions: [],
      db: {
        saveLocation: 'product_will_data',
        saveField: 'details',
        formPath: 'data.pets.details'
      },
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
