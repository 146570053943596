import {willPeopleHelpers} from "./willPeopleHelpers";
import {willRelationshipHelpers} from "./willRelationshipHelpers";
import {willProfessionalOptions} from "./willProfessionalOptions";
import { fieldsetLoader } from "@/mixins/fieldsetLoader";

export const willPetsHelpers = {
    // required a details computed property: only to be used in root level Pets file
    mixins: [willPeopleHelpers, willRelationshipHelpers, willProfessionalOptions, fieldsetLoader],
    mounted() {
        // calculate people options
        this.setOptionsData()

        let attributeAdded = false
        // for old created will insert new fields into data
        if (!('separatePets' in this.details)) {
            // set new fields for old created wills
            this.$set(this.details, 'separatePets', null)
            attributeAdded = true
        }
        if (!('amountYN' in this.details)) {
            // set new fields for old created wills
            this.$set(this.details, 'amountYN', null)
            attributeAdded = true
        }
        if (!('secondaryYN' in this.details)) {
            // set new fields for old created wills
            this.$set(this.details, 'secondaryYN', null)
            attributeAdded = true
        }
        if (attributeAdded) this.save()
    },
    watch: {
        storePeople: {
            immediate: false,
            deep: true,
            handler() {
                this.setOptionsData()
            }
        },
        details: {
            immediate: false,
            deep: true,
            handler() {
                this.setOptionsData()
            }
        }
    },
    computed: {
        primaryRequired () {
            return ([true, false].includes(this.details.primaryPartner) && this.partner) || !this.partner
        },
        secondaryRequired() {
            return this.details.secondaryYN
        },
        details() {
            return this.form.data.pets.details
        },
        data() {
            return this.form.data.pets.details
        },
        storePeople() {
            return this.$store.getters.people
        }
    },
    methods: {
        async syncOptions() {
            this.clearAllPets()
            await this.addPartner()
            this.save()
        },
        async addPartner() {
            // add partner to primary pets
            if (this.partner) {
                // find partner in primary pets
                let partnerPrimaryIndex = this.details.primary.findIndex(client => client.id === this.partner.id)
                if (partnerPrimaryIndex >= 0 && !this.details.primaryPartner) {
                    // remove partner
                    this.details.primary.splice(partnerPrimaryIndex, 1)
                    return true
                } else if (this.details.primaryPartner) {
                    // add partner
                    this.details.primary.push(this.convertPerson(this.partner))
                    // remove partner from secondary
                    let partnerSecondaryIndex = this.details.secondary.findIndex(client => client.id === this.partner.id)
                    if (partnerSecondaryIndex >= 0) this.details.secondary.splice(partnerSecondaryIndex, 1)
                    return true
                }
            } else {
                return false
            }
        },
        clearAllPets() {
            this.details.primary = []
            this.details.secondary = []
        },
        clearSecondary() {
            if (this.details.secondaryYN !== false) return
            this.details.secondary = []
            this.save()
        },
        async enable () {
            if (this.details.separatePets === false) {
                this.clearAllPets()
                this.details.enabled = false
                this.details.primaryPartner = null
                this.details.secondaryYN = null
                this.save()
                await this.removeFieldSets(['PetsCash'])
            } else {
                this.details.enabled = true
                this.save()
                await this.addFieldSets([['PetsCash', 1]])
            }
        },
        setOptionsData() {
            // client_people from server with originally selected people filtered out,
            // mapped to be compatible with a Will then people relationships converted
            let serverPrimary = []
            let serverSecondary = []

            // convert people
            let serverPeople = this.storePeople.map(person => {
                // do not convert professional as they have been added from TypeOfPets in final form for will
                if (person.type === 'professional') return person
                // convert ClientPerson model into will person
                return this.convertPerson(person)
            })

            // filter out primary or secondary options
            let primaryIds = this.details.primary.map(person => person.id)
            let secondaryIds = this.details.secondary.map(person => person.id)
            serverPrimary = serverPeople.filter(person => !primaryIds.includes(person.id) && !secondaryIds.includes(person.id))
            serverSecondary = serverPeople.filter(person => !primaryIds.includes(person.id) && !secondaryIds.includes(person.id))

            // return
            this.primaryOptions = [...serverPrimary].sort((a, b) => a.id - b.id)
            this.secondaryOptions = [...serverSecondary].sort((a, b) => a.id - b.id)
        },
    }
}
