<template>
  <QuestionBase :id="db.saveField"

                :errors="[...jsErrors]"
                :question="question"
                :valid="!!data.length">

    <!-- Selected People -->
    <div class="accordion sub-items">
      <transition-group name="fade">
        <ObjectCard v-for="(person, index) in data"
                    :id="'petPrimaryData' + index"
                    :key="'petPrimaryData' + index"
                    :ref="'petPrimaryData' + index"
                    :index="index"
                    :select-mode="true"
                    :selected="true"
                    :show-delete="false"
                    :title="cardTitle(person)"
                    :value="data[index]"
                    type="petPrimaryData"
                    :loading="loading"
                    @click="deselectConfirm(person, 'Remove person from your primary pet guardians?')"
                    @delete="deletePerson(person.id)"
                    @save="savePerson( null, 'petPrimaryData' + index)">

          <PersonWill v-if="person.type === 'person'"
                      :key="'petPrimary-form-data' + index"
                      v-model="data[index]"
                      :errors-post="errorsPatch"
                      :no-save="true"
                      :objectId="person.id"
                      @save="savePersonForm($event, person)"/>

          <GroupWill v-else-if="person.type === 'group'"
                     :key="'petsPrimary-form-data-group' + index"
                     v-model="data[index]"
                     @save="savePersonForm($event, person)"/>

        </ObjectCard>
      </transition-group>

    </div>

    <!-- New People -->
    <transition name="fade">
      <b-button class="btn-question w-100"
                @click="show.addPerson=true">
        <i class="i-Add text-25 font-weight-800 mr-2"> </i> Add Primary Pet Guardian
      </b-button>
    </transition>

    <WillPeopleModal
        v-model="show.addPerson"
        :dataSelected="data"
        :options="optionsData"
        :show-charities="false"
        :show-groups="true"
        sub-title="Select existing people or add new people. You can select multiple individuals for this role."
        :hide-type-options="false"
        title="Add Pet Guardian"
        @dataSelectedUpdate="data=$event"
        @save="save"/>
  </QuestionBase>
</template>

<script>
import {personHelpers} from "@/mixins/personHelpers";
import {peopleSaveHelpers} from "../../objects/peopleSaveHelpers";
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {saveHelpers} from "@/views/questionnaires/saveHelpers";
import {willPeopleObjectHelpers} from "../helpers/willPeopleObjectHelpers";

import QuestionBase from "../../../QuestionBase";
import ObjectCard from "../../objects/ObjectCard";
import PersonWill from "../../../../fieldsets/sub/people/PersonWill";
import WillPeopleModal from "../helpers/WillPeopleModal";
import GroupWill from "../../../../fieldsets/sub/people/GroupWill";

export default {
  name: 'PrimaryPets',
  components: {
    GroupWill,
    WillPeopleModal,
    PersonWill,
    ObjectCard,
    QuestionBase
  },
  mixins: [
    peopleSaveHelpers,
    personHelpers,
    questionLoadHelpers,
    saveHelpers,
    willPeopleObjectHelpers
  ],
  props: {
    value: {
      type: Array,
      required: false
    },
    details: {
      type: Object,
      required: true
    },
    optionsData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      db: {
        saveLocation: 'product_will_data',
        saveField: 'primaryPets',
        formPath: 'data.pets.details.primary',
        jsonSaveField: 'primary',
      },
      args: {
        skippable: false,
        required: true,
        customErrorMessage: 'You must have more than one Primary Pet, click to select'
      },
      serverErrors: [],
      errorsPost: {},
      errorsPatch: {},
      jsErrors: [],
      skipped: false,
      show: {
        addPerson: false
      }
    }
  },
  computed: {
    question() {
      return {
        title: 'Who would you like to look after your pets?',
        subTitle: this.details.primaryPartner ? null : 'Please confirm who you would like to act as the primary pets. To deselect a person, simply click on their name.',
        tip: null
      }
    },
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  }
}
</script>
